import React, { FunctionComponent, useState, useEffect } from "react";
import { ConveyApi } from "@utils";
import { ICampaignEntity } from "@utils";
import { CampaignForm, CampaignSubmit, CampaignLabel, CampaignError } from "./campaignStyles";
import Select from "react-select";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { Alert, SubmitButton, FlexCenterRow } from "@components/styles";
import { Spinner } from "@components/Spinner";
import { FormInput } from "@components/styles";
import { MultiSelectDropdown } from "./MultiSelectDropdown";
import { TLanguageType } from "@utils";

interface ICampaignEmbedProps {
  campaignId: string;
  campaigns: ICampaignEntity[];
  app: IRootState["app"];
  callback?: (eventData: Record<any, any>) => void;
}

const XCampaignEmbed: FunctionComponent<ICampaignEmbedProps> = ({
  campaignId,
  campaigns,
  callback,
  app
}: ICampaignEmbedProps) => {
  const params = new URLSearchParams(window.location.search);
  const nParam = params.get("n");

  const existingNumber = nParam ? atob(nParam) : null;
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showVerified, setShowVerified] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [code, setCode] = useState("");
  const [subscriberId, setSubscriberId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [groups, setGroups] = useState([]);
  const [language, setLanguage] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showUnsubscribeNotice, setShowUnsubscribeNotice] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<TLanguageType>({});
  const [showError, setShowError] = useState("");

  const counties = campaigns.map((c) => ({ value: c.group_id, label: c.group_name }));

  const moveEnEsToFirst = () => {
    const langs = app.textDialects.map((c) => ({ value: c.code, label: c.label }));
    const esEleIndex = langs.findIndex((lang) => lang.value === "es");
    if (esEleIndex !== -1) {
      const [element] = langs.splice(esEleIndex, 1);
      langs.unshift(element);
    }

    const engEleIndex = langs.findIndex((lang) => lang.value === "en");
    if (engEleIndex !== -1) {
      const [element] = langs.splice(engEleIndex, 1);
      langs.unshift(element);
    }

    return langs;
  };

  const languages = moveEnEsToFirst();

  useEffect(() => {
    if (existingNumber) {
      (async () => {
        const response = await ConveyApi.getCampaignSubscriber({ campaignId, subscriberId: existingNumber });
        if (response.campaign_id) {
          setShowUpdateForm(true);
          setPhoneNumber(response.number);
          setZipcode(response.zipcode);
          setGroups(response.groups.map((group) => group.group_id));
          setCurrentLanguage(languages.find((l) => l.value === response.language));
          setLanguage(response.language);
          setSubscriberId(response.subscriber_id);
        }
      })();
    } else {
      const defaultLanguage = navigator.language ? navigator.language.substring(0, 2) : "en";
      setCurrentLanguage(languages.find((l) => l.value === defaultLanguage));
    }
  }, [existingNumber]);

  const submit = async () => {
    if (!phoneNumber) {
      setPhoneError("Please enter a phone number.");
      return;
    } else {
      setPhoneError("");
    }

    if (zipCode.replace(/\D/g, "").length < 5) {
      setZipCodeError("Please enter a 5 digit zip code.");
      return;
    } else {
      setZipCodeError("");
    }

    setLoading(true);

    const createResponse = await ConveyApi.postCampaignSubscriber({
      phoneNumber,
      campaignId,
      groups,
      language: language || currentLanguage.value,
      zipCode
    });
    setLoading(false);

    if (!createResponse || createResponse.error) {
      setShowError(
        createResponse && createResponse.error ? createResponse.error : "An erro has occurred. Please try again."
      );
    } else if (createResponse.status === "pending") {
      setSubscriberId(createResponse.subscriber_id);
      setShowVerify(true);
    } else {
      callback && callback(createResponse);
      setShowVerified(true);
    }
  };
  const unsubscribe = async () => {
    if (deleteLoading) {
      return;
    }
    setDeleteLoading(true);
    await ConveyApi.deleteCampaignSubscriber({
      campaignId,
      subscriberId
    });
    setShowUnsubscribeNotice(true);
    setTimeout(() => {
      setDeleteLoading(false);
      setShowUnsubscribeNotice(false);
      setShowVerify(false);
      setShowVerified(false);
      setShowUpdateForm(false);
      setPhoneNumber("");
      setZipcode("");
      setGroups([]);
      setCurrentLanguage({});
      setLanguage("");
      setSubscriberId("");
      setCode("");
    }, 1750);
  };
  const verify = async () => {
    setLoading(true);
    const verifyResponse = await ConveyApi.updateCampaignSubscriber({
      phoneNumber,
      subscriberId,
      campaignId,
      code,
      zipCode
    });
    setLoading(false);

    if (verifyResponse.status === "verified") {
      callback && callback(verifyResponse);
      setShowVerified(true);
      setShowVerify(false);
    }
  };

  return (
    <CampaignForm
      onSubmit={(e) => {
        e.preventDefault();
        if (showVerify) {
          verify();
        } else {
          submit();
        }
      }}
    >
      {showError && <Alert alertType="danger">{showError}</Alert>}
      {showVerified && <Alert alertType="success">You have successfully subscribed.</Alert>}
      {(showVerified || !showVerify) && (
        <>
          <CampaignLabel>Phone Number</CampaignLabel>
          <Input
            onChange={(value) => {
              setPhoneNumber(value);
            }}
            readOnly={showUpdateForm || showVerified}
            international={false}
            country="US"
            className="ConveyEmbed--CampaignPhoneInput"
            placeholder="Phone Number"
            value={phoneNumber}
            style={{
              boxSizing: "border-box",
              padding: 6,
              width: "100%",
              maxWidth: 600,
              borderRadius: 4,
              border: "1px solid hsl(0, 0%, 80%)",
              minHeight: 38
            }}
          />
          {phoneError && <CampaignError>{phoneError}</CampaignError>}
          <CampaignLabel>County</CampaignLabel>
          <MultiSelectDropdown
            value={groups}
            placeholder="Choose Multiple Alert Locations"
            options={counties}
            onChange={(options) => {
              setGroups(options.map((option) => option.value));
            }}
          />
          <CampaignLabel>Primary ZIP Code</CampaignLabel>
          <FormInput
            onChange={(e) => {
              setZipcode(e.target.value.slice(0, 5));
            }}
            maxLength={5}
            placeholder="Primary ZIP Code"
            type="number"
            value={zipCode}
            style={{
              boxSizing: "border-box",
              padding: 6,
              width: "100%",
              maxWidth: 600,
              borderRadius: 4,
              border: "1px solid hsl(0, 0%, 80%)",
              minHeight: 38
            }}
          />
          {zipCodeError && <CampaignError>{zipCodeError}</CampaignError>}
          <CampaignLabel>Preferred Language</CampaignLabel>
          <Select
            onChange={(option) => {
              setLanguage(option.value);
              setCurrentLanguage(option);
            }}
            placeholder="What is your preferred language?"
            options={languages}
            classNamePrefix={"ConveyEmbed--CampaignSingleSelect"}
            value={currentLanguage}
          />
        </>
      )}
      {showVerify && (
        <>
          <CampaignLabel>Verification Code</CampaignLabel>
          <input
            onChange={(e) => {
              setCode(e.target.value);
            }}
            placeholder="Enter Verification Code"
            value={code}
            className="ConveyEmbed--CampaignVerifyCodeInput"
            style={{
              padding: 6,
              width: "100%",
              borderRadius: 4,
              border: "1px solid hsl(0, 0%, 80%)",
              minHeight: 38
            }}
          />
        </>
      )}
      {showUnsubscribeNotice && (
        <Alert alertType="success" style={{ marginTop: 25 }}>
          You have been unsubscribed.
        </Alert>
      )}
      {!(showUpdateForm || showVerified) ? (
        <CampaignSubmit type="submit" disabled={loading}>
          {showVerify ? "Verify" : "Subscribe"}
          {loading && <Spinner />}
        </CampaignSubmit>
      ) : showUpdateForm || showVerified ? (
        <FlexCenterRow justify="center" style={{ width: "100%", marginTop: 10, textAlign: "center" }}>
          <CampaignSubmit type="submit" disabled={loading}>
            Update
          </CampaignSubmit>
          <SubmitButton
            onClick={unsubscribe}
            buttonType="danger"
            disabled={deleteLoading}
            style={{ marginTop: 25, marginLeft: 10, borderRadius: 4, height: 38, fontSize: 16 }}
          >
            Unsubscribe
          </SubmitButton>
        </FlexCenterRow>
      ) : null}
    </CampaignForm>
  );
};

const mapStateToProps = ({ conversations, app }: IRootState) => ({
  conversations,
  app
});

export const CampaignEmbed = connect(mapStateToProps, {})(XCampaignEmbed);
