import React, { FunctionComponent, useState, useEffect } from "react";
import { ConveyApi } from "@utils";
import { IConveyCallSimulatorProps } from "@utils";
import { CallSimulatorForm, CallSimulatorSubmit } from "./callSimulatorStyles";
import Select from "react-select";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { Alert, FlexCenterRow, InputLabel } from "@components/styles";
import { Spinner } from "@components/Spinner";
import { TLanguageType } from "@utils";
import { UploadWrap } from "@components/Transcribe/transcribeStyles";
import { SDK } from "@lib/index";

interface ICallSimulatorProps {
  $container: string;
  conversationParams: IConveyCallSimulatorProps["conversationParams"];
  app: IRootState["app"];
}

const XCallSimulator: FunctionComponent<ICallSimulatorProps> = ({
  conversationParams,
  app,
  $container
}: ICallSimulatorProps) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [language, setLanguage] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState<TLanguageType>({});
  const [error, setError] = useState("");
  const [recipientAudio, setRecipientAudio] = useState<File | null>(null);
  const [agentAudio, setAgentAudio] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(false);
  const canSubmit = phoneNumber && language && recipientAudio && agentAudio;

  const moveEnEsToFirst = () => {
    const langs = app.textDialects.map((c) => ({ value: c.code, label: c.label }));
    const esEleIndex = langs.findIndex((lang) => lang.value === "es");
    if (esEleIndex !== -1) {
      const [element] = langs.splice(esEleIndex, 1);
      langs.unshift(element);
    }

    const engEleIndex = langs.findIndex((lang) => lang.value === "en");
    if (engEleIndex !== -1) {
      const [element] = langs.splice(engEleIndex, 1);
      langs.unshift(element);
    }

    return langs;
  };

  const languages = moveEnEsToFirst();

  const submit = async () => {
    if (!phoneNumber) {
      setError("Please enter a phone number.");
      return;
    } else {
      setError("");
    }
    if (!currentLanguage) {
      setError("Please select a language.");
      return;
    } else {
      setError("");
    }
    if (!recipientAudio) {
      setError("Please select a recipient audio file.");
      return;
    } else {
      setError("");
    }
    if (!agentAudio) {
      setError("Please select an agent audio file.");
      return;
    } else {
      setError("");
    }

    setLoading(true);

    const createResponse = await ConveyApi.createConversation({
      phoneNumber,
      language: language || currentLanguage.value,
      ...conversationParams
    });
    setLoading(false);

    if (!createResponse || createResponse.error) {
      setError(
        createResponse && createResponse.error ? createResponse.error : "An erro has occurred. Please try again."
      );
    } else {
      SDK.init({
        agencyId: app.agencyId,
        agencySecret: app.agencySecret,
        phoneNumber,
        options: app.initOptions.options
      }).transcribeCall({
        $container,
        conversationId: createResponse.conversation_id,
        correlationId: phoneNumber,
        recipientAudio,
        agentAudio
      });
      setHide(true);
    }
  };

  const openUploader = (id: string) => {
    document.getElementById(id).click();
  };

  return hide ? null : (
    <CallSimulatorForm
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      {error && <Alert alertType="danger">{error}</Alert>}
      <>
        <InputLabel>Caller Phone Number</InputLabel>
        <Input
          onChange={(value) => {
            setPhoneNumber(value);
          }}
          international={false}
          country="US"
          className="ConveyEmbed--CampaignPhoneInput"
          placeholder="Phone Number"
          value={phoneNumber}
          style={{
            boxSizing: "border-box",
            padding: 6,
            width: "100%",
            maxWidth: 600,
            borderRadius: 4,
            border: "1px solid hsl(0, 0%, 80%)",
            minHeight: 38
          }}
        />
        <InputLabel>Caller Language</InputLabel>
        <Select
          onChange={(option) => {
            setLanguage(option.value);
            setCurrentLanguage(option);
          }}
          placeholder="What is your preferred language?"
          options={languages}
          classNamePrefix={"ConveyEmbed--CallSimulatorSingleSelect"}
          value={currentLanguage}
        />
        <FlexCenterRow justify="center" style={{ marginTop: 20 }}>
          <UploadWrap>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                openUploader("recipientFile");
              }}
            >
              {recipientAudio ? "Recipient Audio Selected" : "Select Recipient Audio"}
            </a>
            <input
              id="recipientFile"
              hidden={true}
              type="file"
              onChange={() => {
                const files = (document.getElementById(`recipientFile`) as HTMLInputElement).files;
                setRecipientAudio(files[0]);
              }}
              accept="audio/*"
            />
          </UploadWrap>
          <UploadWrap>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                openUploader("agentFile");
              }}
            >
              {agentAudio ? "Agent Audio Selected" : "Select Agent Audio"}
            </a>
            <input
              id="agentFile"
              hidden={true}
              type="file"
              onChange={() => {
                const files = (document.getElementById(`agentFile`) as HTMLInputElement).files;
                setAgentAudio(files[0]);
              }}
              accept="audio/*"
            />
          </UploadWrap>
        </FlexCenterRow>
      </>
      <FlexCenterRow justify="center" style={{ width: "100%", marginTop: 10, textAlign: "center" }}>
        <CallSimulatorSubmit type="submit" disabled={loading || !canSubmit}>
          Create{loading && <Spinner />}
        </CallSimulatorSubmit>
      </FlexCenterRow>
    </CallSimulatorForm>
  );
};

const mapStateToProps = ({ conversations, app }: IRootState) => ({
  conversations,
  app
});

export const CallSimulator = connect(mapStateToProps, {})(XCallSimulator);
